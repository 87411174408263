require('./bootstrap');
require('slick-carousel');



;(function( $, w ){
    "use strict";    
    let body = $('body');
    var edwiges = {
        init: () => {
            if( body.hasClass('home') ){
				edwiges.home();				
            }

            if(body.hasClass('contato')){
                edwiges.contato();
            }

            if(body.hasClass('register')){
                edwiges.register();
            }

            if(body.hasClass('produto')){
                edwiges.produto();
            }

            $(".nossasmarcas").slick({
                slidesToShow: 6,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 1500,
                arrows: true,
                dots: false,
                pauseOnHover: false,
                responsive: [{
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 4
                    }
                }, {
                    breakpoint: 520,
                    settings: {
                        slidesToShow: 3
                    }
                }]
            });

            edwiges.backtotop();

            $("#menu-btn").on("click", function() {
                $("body").toggleClass("menu-active");
              });
        },

        backtotop: () => {
            var btn = $('#back-top');

            $(window).scroll(function() {
            if ($(window).scrollTop() > 300) {
                btn.addClass('show');
            } else {
                btn.removeClass('show');
            }
            });

            btn.on('click', function(e) {
                e.preventDefault();
                $('html, body').animate({scrollTop:0}, '300');
            });


        },

        register: () =>{
            //Busca na API do IBGE os estados e adiciona as opções com o ID referente ao seu estado (data-id)
            $.getJSON('https://servicodados.ibge.gov.br/api/v1/localidades/estados/', function (uf) {
                var options = '<option value="" selected disabled>– Selecione seu estado –</option>'; 
                
                //Ordem alfabetica dos estados
                var features = uf.sort((a,b) => {
                    return (
                    (a.nome < b.nome && -1) ||
                    (a.nome > b.nome && 1) ||
                    0
                    ); 
                });


                for (var i = 0; i < features.length; i++) { 
                    options += '<option data-id="' + features[i].id + '" value="' + features[i].nome + '" >' + features[i].nome + '</option>'; 
                }

                $("select[name='uf']").html(options);

            });
            
            // Assim que houver mudança no select do estado, executa o script populando com a cidade referente ao ID (data-id) da opção selecionada.
            // Fazendo assim uma busca dos municipios/cidades oferecida diretamente pela API do IBGE.
            
            $("select[name='uf']").change(function () {
                if ($(this).val()) {
                    const ufSelect = $(this).find("option:selected").attr('data-id');

                    $.getJSON('https://servicodados.ibge.gov.br/api/v1/localidades/estados/'+ufSelect+'/municipios', {id: $(this).find("option:selected").attr('data-id')}, function (city) {            
                        var options = '<option value="" disabled selected>– Selecione sua cidade –</option>';

                        for (var i = 0; i < city.length; i++) {
                            options += '<option value="' + city[i].nome + '" >' + city[i].nome + '</option>';
                        }

                        $("select[name='cidade']").html(options);

                    });

                } else {
                    $("select[name='cidade']").html('<option value="" disabled selected>– Selecione sua cidade –</option>');
                }

            });
        },

        produto:()=>{
            //-- Click on detail
            $("ul.menu-items > li").on("click",function(){
                $("ul.menu-items > li").removeClass("active");
                $(this).addClass("active");
            })

            $(".attr,.attr2").on("click",function(){
                var clase = $(this).attr("class");

                $("." + clase).removeClass("active");
                $(this).addClass("active");
            });

            //-- Click on QUANTITY
            $(".btn-minus").on("click",function(){
                var now = $(".section > div > input").val();
                if ($.isNumeric(now)){
                    if (parseInt(now) -1 > 0){ now--;}
                    $(".section > div > input").val(now);
                }else{
                    $(".section > div > input").val("1");
                }
            })            
            $(".btn-plus").on("click",function(){
                var now = $(".section > div > input").val();
                if ($.isNumeric(now)){
                    $(".section > div > input").val(parseInt(now)+1);
                }else{
                    $(".section > div > input").val("1");
                }
            });   
            
            $(".produtosrelacionados").slick({
                slidesToShow: 6,
                slidesToScroll: 1,
                autoplay: false,
                autoplaySpeed: 1500,
                arrows: true,
                dots: false,
                pauseOnHover: false,
                responsive: [{
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 4
                    }
                }, {
                    breakpoint: 520,
                    settings: {
                        slidesToShow: 2
                    }
                }]
            });
            
            // $('.attrib .option').on('click', function(){
            //     $(this).siblings().removeClass('activ');
            //     $(this).addClass('activ');
            // })
            // $('.zoomControl').on('click',function(){
            //     $(this).parents('.productCard').addClass('morph');
            //     $('body').addClass('noScroll');
            // })
            // $('.closePreview').on('click',function(){
            //     $(this).parents('.productCard').removeClass('morph');
            //     $('body').removeClass('noScroll');
            // })
            // $('.movControl').on('click',function(){
            //     let imgActiv = $(this).parents('.preview').find('.imgs img.activ');
            //     if ($(this).hasClass('left')) {
            //     imgActiv.index() == 0 ? $('.imgs img').last().addClass('activ') : $('.imgs img.activ').prev().addClass('activ');
            //     } else {
            //     imgActiv.index() == ($('.imgs img').length - 1) ? $('.imgs img').first().addClass('activ') : $('.imgs img.activ').next().addClass('activ');
            //     }
            //     imgActiv.removeClass('activ');
            // })
              
        },

        contato: () =>{
            console.log('contato');
            window.addEventListener('load', function() {
                // Fetch all the forms we want to apply custom Bootstrap validation styles to
                var forms = document.getElementsByClassName('needs-validation');
                // Loop over them and prevent submission
                var validation = Array.prototype.filter.call(forms, function(form) {
                  form.addEventListener('submit', function(event) {
                    if (form.checkValidity() === false) {
                      event.preventDefault();
                      event.stopPropagation();
                    }
                    form.classList.add('was-validated');
                  }, false);
                });
              }, false);
        },


        home: () => {
            // $(".slider").slick();
         

            function createSlick(){  
                $(".sliderproducts").not('.slick-initialized').slick({
                    centerMode: true,
                    autoplay: false,
                    dots: false,
                    
                  
                
                    slidesToShow: 3,
                    responsive: [{ 
                        breakpoint: 768,
                        settings: {
                            dots: false,
                            arrows: false,
                            infinite: false,
                            slidesToShow: 1,
                            slidesToScroll: 1
                        } 
                    }]
                });	
            
            }            
            createSlick();
            $(window).on( 'resize', createSlick );






        }
            
    };
    
   
        
    window.onload = function() { 
		$(edwiges.init); 		
	};



})( jQuery );